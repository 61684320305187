import React from 'react'
import { Link } from 'gatsby'

import { withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListSubheader from '@material-ui/core/ListSubheader'

import LogoPhileass from '../../images/logo_phileass_04.svg'
import ImgIcTel from '../../images/ic_tel_02.svg'

import { siteTheme } from './theme'

import ImgFacebook from '../../images/ic_facebook.svg'
import ImgTwitter from '../../images/ic_twitter.svg'
import ImgLinkedIn from '../../images/ic_linkedIn.svg'
import ImgViadeo from '../../images/ic_viadeo.svg'

const styles = theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  footer: {
    backgroundColor: siteTheme.color2,
    // padding: `${theme.spacing.unit * 8}px 0`,
    paddingTop: '40px',
    paddingBottom: '50px',
    color: siteTheme.white
  },
  footerGrid: {
    flexGrow: 1
  },
  footerGridItem: {
    paddingTop: '32px'
  },
  footerSectionTitle: {
    fontSize: '1.5rem',
    fontWeight: 500,
    color: siteTheme.white
  },
  logo: {
    paddingTop: theme.spacing.unit,
    width: '100px',
    height: 'auto'
  },
  socialGrid: {
    marginTop: '16px'
  },
  supportSeparator: {
    margin: '16px 0px',
    width: '20px',
    height: '2px',
    backgroundColor: siteTheme.white
  },
  supportText: {
    fontSize: '14px',
    lineHeight: '16px',
    color: siteTheme.white
  },
  phoneContainer: {
    padding: '12px 0px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& a': {
      color: 'inherit',
      textDecoration: 'none'
    }
  },
  imgPhoneContainer: {
    flex: '0 0 30px',
    height: '20px'
  },
  imgPhone: {
    width: '20px',
    height: 'auto',
    margin: '0px 10px 0px 0px'
  },
  textPhoneContainer: {
    flex: '1 1 auto'
  },
  textPhone: {
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '18px',
    color: siteTheme.white
  },
  listHeading: {
    padding: '0px',
    color: siteTheme.white,
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '32px'
    // textTransform: 'uppercase'
  },
  listItem: {
    padding: `6px 0px`
  },
  listItemText: {
    color: siteTheme.white,
    fontSize: '14px',
    lineHeight: '18px'
  },
  listLink: {
    color: 'inherit',
    textDecoration: 'none'
  },
  trademark: {
    color: siteTheme.white,
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 'bold',
    textAlign: 'right',
    padding: '16px 0px'
  }
})

function Footer (props) {
  const { classes } = props

  return (
    <React.Fragment>
      <footer className={classes.footer}>
        <div className={classes.layout}>
          <Grid container
            className={classes.footerGrid}
            spacing={32}
          >
            <Grid item
              xs={12} sm={12} md
              className={classes.footerGridItem}
            >
              <Link to='/'>
                <img src={LogoPhileass} alt='Logo Phileass' className={classes.logo} />
              </Link>
              <Grid container
                className={classes.socialGrid}
                direction='row'
                justify='flex-start'
                alignItems='center'
                spacing={24}
              >
                <Grid item>
                  <a href='https://www.facebook.com/Phileass-171073663491475/'><img src={ImgFacebook} alt='Icon Facebook' /></a>
                </Grid>
                <Grid item>
                  <a href='https://twitter.com/Phileass_'><img src={ImgTwitter} alt='Icon Twitter' /></a>
                </Grid>
                <Grid item>
                  <a href='https://www.linkedin.com/company/26471726/'><img src={ImgLinkedIn} alt='Icon LinkedIn' /></a>
                </Grid>
                <Grid item>
                  <a href='https://fr.viadeo.com/fr/company/phileass'><img src={ImgViadeo} alt='Icon Viadeo' /></a>
                </Grid>
              </Grid>
              <div className={classes.supportSeparator} />
              <Typography className={classes.supportText}>
                Contactez notre service client à tout moment&nbsp;:
              </Typography>
              <a href='tel:+33972638706' className={classes.phoneContainer}>
                <div className={classes.imgPhoneContainer}>
                  <img src={ImgIcTel} alt='Icon Téléphone' className={classes.imgPhone} />
                </div>
                <div className={classes.textPhoneContainer}>
                  <Typography component='p' className={classes.textPhone}>
                    09&nbsp;72&nbsp;63&nbsp;87&nbsp;06
                  </Typography>
                </div>
              </a>
              {/* <Typography className={classes.supportContact}>
                <a href='tel:+33972638706'>
                  <img src={ImgIcTel} alt='Icon Téléphone' />
                  09&nbsp;72&nbsp;63&nbsp;87&nbsp;06
                </a>
              </Typography> */}
            </Grid>
            <Grid item
              xs={6} sm={3} md
              className={classes.footerGridItem}
            >
              <List>
                <ListSubheader className={classes.listHeading}>
                  Société
                </ListSubheader>
                <ListItem className={classes.listItem}>
                  <Typography className={classes.listItemText}>
                    <Link to='/qui-sommes-nous' className={classes.listLink}>Qui sommes-nous ?</Link>
                  </Typography>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <Typography className={classes.listItemText}>
                    <Link to='/presse' className={classes.listLink}>Presse</Link>
                  </Typography>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <Typography className={classes.listItemText}>
                    <Link to='/contact' className={classes.listLink}>Contact</Link>
                  </Typography>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <Typography className={classes.listItemText}>
                    <Link to='/faq' className={classes.listLink}>FAQ</Link>
                  </Typography>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <Typography className={classes.listItemText}>
                    <Link to='/plan-du-site' className={classes.listLink}>Plan du site</Link>
                  </Typography>
                </ListItem>
              </List>
            </Grid>
            <Grid item
              xs={6} sm={3} md
              className={classes.footerGridItem}
            >
              <List>
                <ListSubheader className={classes.listHeading}>
                  Légal
                </ListSubheader>
                <ListItem className={classes.listItem}>
                  <Typography className={classes.listItemText}>
                    <Link to='/conditions-generales-d-utilisation' className={classes.listLink}>Conditions Générales d’Utilisation</Link>
                  </Typography>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <Typography className={classes.listItemText}>
                    <Link to='/donnees-personnelles' className={classes.listLink}>Données personnelles</Link>
                  </Typography>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <Typography className={classes.listItemText}>
                    <Link to='/mentions-legales' className={classes.listLink}>Mentions légales</Link>
                  </Typography>
                </ListItem>
              </List>
            </Grid>
            <Grid item
              xs={6} sm={3} md
              className={classes.footerGridItem}
            >
              <List>
                <ListSubheader className={classes.listHeading}>
                  Partenaires
                </ListSubheader>
                <ListItem className={classes.listItem}>
                  <Typography className={classes.listItemText}>
                    <Link to='/assureurs' className={classes.listLink}>Assureurs</Link>
                  </Typography>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <Typography className={classes.listItemText}>
                    <Link to='/affiliation' className={classes.listLink}>Affiliation</Link>
                  </Typography>
                </ListItem>
              </List>
            </Grid>
            <Grid item
              xs={6} sm={3} md
              className={classes.footerGridItem}
            >
              {/* <List>
                <ListSubheader className={classes.listHeading}>
                  Blog
                </ListSubheader>
                <ListItem className={classes.listItem}>
                  <Typography className={classes.listItemText}>
                    <a href='http://blog.phileass.com' className={classes.listLink}>Lire les articles</a>
                  </Typography>
                </ListItem>
              </List> */}
              <List>
                <ListSubheader className={classes.listHeading}>
                  Documentation
                </ListSubheader>
                <ListItem className={classes.listItem}>
                  <Typography className={classes.listItemText}>
                    <a href='/metiers' className={classes.listLink}>Métiers</a>
                  </Typography>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <Typography className={classes.listItemText}>
                    <a href='/solutions-d-assurance' className={classes.listLink}>Assurances</a>
                  </Typography>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <Typography className={classes.listItemText}>
                    <a href='http://blog.phileass.com' className={classes.listLink}>Blog</a>
                  </Typography>
                </ListItem>
              </List>
            </Grid>
          </Grid>
          <Typography className={classes.trademark}>
            &copy; 2019 Phileass.com Tous droits réservés
          </Typography>
        </div>
      </footer>
    </React.Fragment>
  )
}

export default withStyles(styles)(Footer)
