import React from 'react'
import _ from 'lodash'

import { useStaticQuery, graphql, Link } from 'gatsby'

import { withStyles } from '@material-ui/core'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

import ListItemCollapse from '../ui/ListItemCollapse'

const styles = theme => ({
})

function HamburgerMenuAssurances (props) {
  const {
    className
    // classes
  } = props

  const data = useStaticQuery(graphql`
    query {
      strapiWebsection(
        name: { eq: "assurances" }
      ) {
        id
        name
        label
        path
      }
      allStrapiWebpage(
        filter: {
          webSection: {
            name: { eq: "assurances" }
          }
          pageType: { ne: "Index" }
        }
        sort: {
          order: ASC,
          fields: [sortIndex, label]
        }
      ) {
        edges {
          node {
            id
            name
            label
            path
            heading
            description
            imgLogo {
              url
            }
          }
        }
      }
    }
  `)

  return (
    <ListItemCollapse
      listItem={data.strapiWebsection.label}
      className={className}
    >
      {
        data.allStrapiWebpage.edges.map(edge => (
          <ListItem button
            component={Link} to={edge.node.path}
          >
            <ListItemText inset primary={edge.node.label} />
          </ListItem>
        ))
      }
    </ListItemCollapse>
  )
}

export default withStyles(styles)(HamburgerMenuAssurances)
