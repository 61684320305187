import React from 'react'

import _ from 'lodash'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import IcExpandLess from '@material-ui/icons/ExpandLess'
import IcExpandMore from '@material-ui/icons/ExpandMore'
import Collapse from '@material-ui/core/Collapse'

import { withStyles } from '@material-ui/core'

const styles = theme => ({
})

class ListItemCollapse extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      menuOpened: false
    }

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick () {
    this.setState(state => ({ menuOpened: !state.menuOpened }))
  }

  render () {
    const {
      listItem,
      className,
      // classes,
      children
    } = this.props

    const listItemComp = _.isString(listItem)
      ? (<ListItemText primary={listItem} />)
      : listItem

    return (
      <React.Fragment>
        <ListItem button
          onClick={this.handleClick}
          className={className}
        >
          { listItemComp }
          { this.state.menuOpened ? <IcExpandLess /> : <IcExpandMore /> }
        </ListItem>
        <Collapse in={this.state.menuOpened}
          timeout='auto'
          unmountOnExit
        >
          <List component='div' disablePadding>
            { children }
          </List>
        </Collapse>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(ListItemCollapse)
