
import { createMuiTheme } from '@material-ui/core'

const siteColors = {
  // Dark blue
  color1: '#00376e',
  // blue std dark
  color2: '#0051a3',
  // blue std
  color3: '#198cff',
  // Light blue
  color4: '#01eefd',
  // Light grey
  color5: '#f8f8f8',
  // Dark grey
  color6: '#6c6c6c',
  // Red
  color7: '#f44336', // red[500],
  // Text grey
  // color: #3A4451;
  color8: '#3a4451',
  // Std
  black: '#000000',
  white: '#FFFFFF',
  hoverDark: 'rgba(0,0,0,0.02)',
  hoverLight: 'rgba(255,255,255,0.02)'
}

const siteTheme = {
  ...siteColors,
  gradient1: `linear-gradient(45deg, ${siteColors.color3} 0%, ${siteColors.color4} 100%)`,
  // gradient1: 'linear-gradient(-45deg, red 0%, yellow 100%)',
  gradient2: `linear-gradient(0deg, ${siteColors.color5} 0%, ${siteColors.white} 100%)`,
  boxShadowNone: 'none',
  boxShadow1: '0 10px 50px 0 rgba(0,0,0,0.05)',
  boxShadow2: '0 10px 50px 0 rgba(0,0,0,0.1)',
  boxShadow3: '0 10px 50px 0 rgba(0,0,0,0.15)',
  boxShadow4: '0 10px 50px 0 rgba(0,0,0,0.2)'
}

const muiTheme = createMuiTheme({
  palette: {
    // Primary: color3
    primary: {
      // main: '#FF0000',
      main: siteColors.color3,
      light: siteColors.color4,
      dark: siteColors.color2,
      contrastText: siteColors.white
    },
    // Secondary: color4
    secondary: {
      main: siteColors.color4,
      // light: siteColors.color4,
      dark: siteColors.color3,
      contrastText: siteColors.white
    },
    // Error: ??
    error: {
      main: siteColors.color7
      // light: siteColors.color4,
      // dark: siteColors.color2,
      // contrastText: siteColors.color6
    },
    // text: {
    //   primary: '',
    //   secondary: '',
    //   disabled: '',
    //   hint: ''
    // },
    background: {
      default: siteColors.white,
      paper: siteColors.white
    },
    action: {
      hover: siteColors.hoverDark,
      hoverOpacity: 0.04
    }
  },
  // shadows: [
  //   'none',
  //   siteTheme.boxShadow1,
  //   siteTheme.boxShadow2,
  //   siteTheme.boxShadow3,
  //   siteTheme.boxShadow4
  // ],
  typography: {
    useNextVariants: true,
    fontFamily: 'Raleway, sans-serif',
    fontSize: 14,
    button: {
      fontSize: '16px',
      fontWeight: 'bold',
      lineHeight: '22px'
      // textTransform: 'none'
      // fontWeight: 600
    },
    h1: {
      // fontSize: '3rem',
      // fontWeight: 700,
      // color: siteColors.color3
      fontSize: '40px',
      fontWeight: 'bold',
      lineHeight: '44px',
      letterSpacing: 'normal',
      color: siteTheme.color3
    },
    h2: {
      fontSize: '28px',
      fontWeight: 'bold',
      lineHeight: '32px',
      letterSpacing: 'normal',
      color: siteTheme.color8
    },
    h3: {
      fontSize: '18px',
      fontWeight: 'bold',
      lineHeight: '24px',
      letterSpacing: 'normal',
      color: siteTheme.color8
    },
    subtitle1: {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '28px',
      letterSpacing: 'normal',
      color: siteTheme.color8
    },
    body1: {
      fontSize: '16px',
      fontWeight: 'normal',
      lineHeight: '24px',
      letterSpacing: 'normal',
      color: siteTheme.color8
    },
    body2: {
      fontSize: '16px',
      fontWeight: 'normal',
      lineHeight: '24px',
      letterSpacing: 'normal',
      color: siteTheme.color8
    }
  },
  shape: {
    borderRadius: 6
  }
})

// Reset shadows
muiTheme.shadows[1] = siteTheme.boxShadow1
muiTheme.shadows[2] = siteTheme.boxShadow2
muiTheme.shadows[3] = siteTheme.boxShadow3
muiTheme.shadows[4] = siteTheme.boxShadow4

export { siteTheme, muiTheme }
