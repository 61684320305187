import React from 'react'

import { Link } from 'gatsby'
import _ from 'lodash'

import { withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'

import { siteTheme } from '../layout/theme'

const styles = theme => ({
  card: {
    height: '100%',
    // boxShadow: siteTheme.boxShadowNone,
    backgroundColor: 'transparent'
  },
  cardActionArea: {
    height: '100%'
    // '&:hover': {
    //   backgroundColor: siteTheme.hoverDark
    // }
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start'
  },
  logoContainer: {
    flex: '1 0 auto',
    display: 'block',
    width: '80px'
  },
  itemLogo: {
    height: 'auto',
    width: '50px'
  },
  textContainer: {
    flex: '1 1 auto',
    display: 'block',
    width: '100%'
  },
  itemTitle: {
    color: siteTheme.color6,
    fontSize: '18px',
    fontWeight: 'bold',
    lineHeight: '21px'
  },
  itemText: {
    color: siteTheme.color6,
    fontSize: '14px',
    lineHeight: '20px'
  }
})

function AddCardActionArea (props) {
  const {
    path,
    children,
    className
  } = props
  if (_.isNil(path)) {
    return children
  }
  return (
    <CardActionArea component={Link} to={path} className={className}>
      {children}
    </CardActionArea>
  )
}

class MegaMenuCard extends React.Component {
  render () {
    const {
      label,
      description,
      path,
      imgLogo,
      raised,
      elevation,
      children,
      classes
    } = this.props

    return (
      <Card
        raised={raised}
        elevation={elevation}
        className={classes.card}
      >
        <AddCardActionArea
          path={path}
          className={classes.cardActionArea}
        >
          <CardContent className={classes.cardContent}>
            <div className={classes.logoContainer}>
              {
                imgLogo &&
                <img classeName={classes.itemLogo}
                  src={imgLogo.url ? imgLogo.url : imgLogo}
                  alt={label}
                />
              }
            </div>
            <div className={classes.textContainer}>
              <Typography className={classes.itemTitle}>
                {label}
              </Typography>
              <Typography className={classes.itemText}>
                {description}
              </Typography>
              {children}
            </div>
          </CardContent>
        </AddCardActionArea>
      </Card>
    )
  }
}

MegaMenuCard.defaultProps = {
  raised: false,
  elevation: 0
}

export default withStyles(styles)(MegaMenuCard)
