import React from 'react'

import { withStyles } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Drawer from '@material-ui/core/Drawer'
import Divider from '@material-ui/core/Divider'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'

const styles = theme => ({
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    // ...theme.mixins.toolbar,
    // justifyContent: 'flex-end'
    justifyContent: 'flex-start'
  },
  separator: {
    flex: '1 0 auto'
  }
})

class HamburgerMenu extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      menuOpened: false
    }

    this.toggleMenu = (open) => () => {
      this.setState({
        menuOpened: open
      })
    }
  }

  render () {
    const {
      headerContent,
      className,
      classes,
      children
    } = this.props

    return (
      <React.Fragment>
        <IconButton
          className={className}
          color='primary'
          aria-label='Menu'
          onClick={this.toggleMenu(true)}
        >
          <MenuIcon />
        </IconButton>
        <Drawer
          variant='temporary'
          open={this.state.menuOpened}
          anchor='top'
          onClose={this.toggleMenu(false)}
        >
          <div className={classes.drawerHeader}>
            { headerContent }
            <div className={classes.separator} />
            <IconButton
              className={className}
              color='primary'
              aria-label='MenuClose'
              onClick={this.toggleMenu(false)}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <Divider />
          { children }
        </Drawer>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(HamburgerMenu)
