import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql, StaticQuery } from 'gatsby'

const SEO = ({ title, description, image, pathname }) => (
  <StaticQuery
    query={graphql`
      query SEO {
        site {
          siteMetadata {
            defaultTitle: title
            titleTemplate
            defaultDescription: description
            siteUrl
            defaultImage: image
          }
        }
      }
    `}

    render={({
      site: {
        siteMetadata: {
          defaultTitle,
          titleTemplate,
          defaultDescription,
          siteUrl,
          defaultImage
        }
      }
    }) => {
      const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: `${siteUrl}${image || defaultImage}`,
        url: `${siteUrl}${pathname || '/'}`
      }

      const schemaOrg = [
        {
          '@context': 'http://schema.org',
          '@type': 'Organization',
          url: seo.url,
          name: seo.title,
          alternateName: defaultTitle,
          logo: `${siteUrl}${defaultImage}`
        }
      ]

      return (
        <>
          <Helmet title={seo.title} titleTemplate={titleTemplate}>
            {/* Meta tags */}
            {seo.description && <meta name='description' content={seo.description} />}
            {seo.image && <meta name='image' content={seo.image} />}
            {/* Og tags */}
            {seo.url && <meta property='og:url' content={seo.url} />}
            {seo.title && <meta property='og:title' content={seo.title} />}
            {seo.description && <meta property='og:description' content={seo.description} />}
            {seo.image && <meta property='og:image' content={seo.image} />}
            {/* SchemaOrg script */}
            <script type='application/ld+json'>{JSON.stringify(schemaOrg)}</script>
          </Helmet>
        </>
      )
    }}
  />
)

export default SEO
