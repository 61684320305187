import React from 'react'
import _ from 'lodash'

import { useStaticQuery, graphql, Link } from 'gatsby'

import { withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import MegaMenu from '../ui/MegaMenu'
import MegaMenuCard from '../ui/MegaMenuCard'

import IcAutreMetier from '../../images/icons/ic_autres.svg'

const styles = theme => ({
  quoteButton: {
    width: '100%',
    margin: '30px 0px',
    textTransform: 'none'
  }
})

function MegaMenuAssurances (props) {
  const {
    activeSection,
    className,
    classes
  } = props

  const data = useStaticQuery(graphql`
    query {
      strapiWebsection(
        name: { eq: "assurances" }
      ) {
        id
        name
        label
        path
      }
      allStrapiWebpage(
        filter: {
          webSection: {
            name: { eq: "assurances" }
          }
          pageType: { ne: "Index" }
        }
        sort: {
          order: ASC,
          fields: [sortIndex, label]
        }
      ) {
        edges {
          node {
            id
            name
            label
            path
            heading
            description
            imgLogo {
              url
            }
          }
        }
      }
    }
  `)

  return (
    <MegaMenu
      menuLabel={data.strapiWebsection.label}
      selected={activeSection === 'assurances'}
      className={className}
    >
      <Grid container
        spacing={16}
        direction='row'
        justify='space-between'
        alignItems='stretch'
      >
        <Grid
          item
          xs
          container
          spacing={16}
          direction='row'
          justify='space-between'
          alignItems='stretch'
        >
          {
            data.allStrapiWebpage.edges.map(edge => (
              <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                <MegaMenuCard
                  label={edge.node.label}
                  description={edge.node.heading || edge.node.description}
                  path={edge.node.path}
                  imgLogo={edge.node.imgLogo}
                />
              </Grid>
            ))
          }
        </Grid>
        <Grid
          item
          xs={12} sm={6} md={4} lg={3} xl={1}
        >
          <MegaMenuCard
            // raised
            // elevation={1}
            label='Autre assurance'
            description="Si vous recherchez un autre type de police d'assurance, vous pouvez spécifier votre besoin à travers notre formulaire de devis."
            imgLogo={IcAutreMetier}
          >
            <Button component={Link} to='/devis'
              color='primary' variant='contained'
              className={classes.quoteButton}
            >
              Obtenir mes tarifs
            </Button>
          </MegaMenuCard>
        </Grid>
      </Grid>
    </MegaMenu>
  )
}

export default withStyles(styles)(MegaMenuAssurances)
