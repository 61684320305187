import React from 'react'
import _ from 'lodash'

import { withStyles } from '@material-ui/core'

import Button from '@material-ui/core/Button'
import Popover from '@material-ui/core/Popover'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

// import IconButton from '@material-ui/core/IconButton'

import classNames from 'classnames'

import { siteTheme } from '../layout/theme'

const styles = theme => ({
  menuLabel: {
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '21px',
    textTransform: 'none',
    color: siteTheme.white,
    boxShadow: siteTheme.boxShadowNone,
    backgroundColor: 'transparent',
    borderRadius: 0,
    margin: theme.spacing.unit,
    // '&:hover': {
    //   // color: theme.palette.common.white,
    //   // backgroundColor: 'rgba(255,255,255,0.25)'
    //   backgroundColor: 'transparent'
    // },
    '&.selected': {
      borderBottom: `solid 2px ${siteTheme.white}`
    }
  },
  menuIcon: {
    marginLeft: theme.spacing.unit
  },
  popover: {
    //
  },
  menuContainer: {
    padding: theme.spacing.unit * 2,
    boxShadow: siteTheme.boxShadow4
    // maxWidth: '90vw'
    // maxWidth: 'calc(100vw - 500)'
    // width: 'auto',
  }
})

class MegaMenu extends React.Component {
  constructor (props) {
    super(props)

    // State
    this.state = {
      menuAnchorEl: null,
      menuOpened: false
    }

    // Bind class methods
    this.handleClick = this.handleClick.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  handleClick (event) {
    const { currentTarget } = event
    this.setState({
      menuAnchorEl: currentTarget,
      menuOpened: (!_.isNil(currentTarget))
    })
  }

  handleClose () {
    this.setState({
      menuAnchorEl: null,
      menuOpened: false
    })
  }

  render () {
    const {
      id,
      menuLabel,
      selected,
      className,
      classes,
      children
    } = this.props
    const {
      menuAnchorEl,
      menuOpened
    } = this.state

    const compId = id || 'megamenu-popper'
    const valId = (menuOpened ? compId : undefined)

    const compClassName = className || classes.menuLabel

    return (
      <div>
        <Button
          aria-describedby={valId}
          className={classNames(compClassName, { selected: selected })}
          // variant='contained'
          onClick={this.handleClick}
        >
          {menuLabel}
          <ExpandMoreIcon className={classes.menuIcon} fontSize='small' />
        </Button>
        <Popover
          id={valId}
          open={menuOpened}
          anchorEl={menuAnchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          // marginThreshold={45}
          className={classes.popover}
          classes={{
            paper: classes.menuContainer
          }}
        >
          {children}
        </Popover>
      </div>
    )
  }
}

export default withStyles(styles)(MegaMenu)
